export const formatToPattern = (value: string, pattern: string): string => {
  const maskLength = [...pattern.matchAll(/#/g)].length
  if (maskLength !== value.length) return value

  let i = 0
  return pattern.replace(/#/g, (): string => {
    i += 1
    return value[i - 1]
  })
}

export const cellphonePattern = (cellphone: string): string =>
  formatToPattern(cellphone, '(##) #####-####')

export const cpfPattern = (cpf: string): string => formatToPattern(cpf, '###.###.###-##')

export const datePattern = (date: string): string =>
  formatToPattern(date.replace(/-/g, ''), '##/##/####')

// TODO: move to Lib Util project
export const currencyMask = (
  inputValue: string | undefined,
  currency: 'BRL' | 'USD' | undefined,
) => {
  if (!inputValue) return ''

  const isFormatted = inputValue.includes('$')

  const withoutCurrency = inputValue.split(' ')[1]
  if (withoutCurrency && !isValidNumber(withoutCurrency)) return inputValue

  const normalizedValue = isFormatted
    ? inputValue.replace(/[^\d]/g, '').replace(/(\d\d?)$/, '.$1')
    : inputValue

  const [integerNumbers, floatingNumbers] = normalizedValue.split('.')

  const interger = BigInt(integerNumbers)
  const str = interger.toLocaleString('pt-BR', {
    style: 'currency',
    currency: currency || 'BRL',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
  })

  const float = floatingNumbers ? floatingNumbers.padEnd(2, '0') : '00'

  return str.replace(',00', `,${float}`)
}

export function isValidNumber(str: string) {
  const numberRegex = /^-?\d+(\.\d+)?$/
  return numberRegex.test(str)
}
