import { css, FlattenSimpleInterpolation } from 'styled-components'

import * as Types from './types'

type ModifiersProps = Partial<Types.TextProps>

export const DEFAULT_COLOR_WEIGHT: Types.ColorWeight = 400
export const TITLE_COLOR_WEIGHT: Types.ColorWeight = 500

const modifiersSemiBold = ({ semiBold }: ModifiersProps) => css`
  font-style: normal;
  font-weight: ${semiBold ? 500 : 'normal'};
`

const CitrinaVariants: Record<
  Types.SemiBoldVariantsProps['variant'],
  (props: ModifiersProps) => FlattenSimpleInterpolation
> = {
  'web-head': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Citrina;
    font-size: 104px;
    line-height: 110%;
    letter-spacing: -2%;
  `,
  'web-title': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Citrina;
    font-size: 80px;
    line-height: 110%;
    letter-spacing: -2%;
  `,
  'web-subtitle': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Citrina;
    font-size: 72px;
    line-height: 110%;
    letter-spacing: -2%;
  `,
  'headline-master': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Inter;
    font-size: 48px;
    line-height: normal;
  `,
  'headline-super': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Inter;
    font-size: 40px;
    line-height: normal;
  `,
  'headline-big': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Inter;
    font-size: 32px;
    line-height: normal;
    letter-spacing: 0px;
  `,
  'headline-h1': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Inter;
    font-size: 24px;
    line-height: normal;
    letter-spacing: 0px;
    font-weight: ${props.semiBold ? 600 : 'normal'};
  `,
  'headline-h2': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Inter;
    font-size: 20px;
    line-height: normal;
    letter-spacing: 0px;
    font-variation-settings: 'opsz' 12;
    font-weight: ${props.semiBold ? 600 : 'normal'};
  `,
  'headline-h3': (props: ModifiersProps) => css`
    ${modifiersSemiBold(props)}
    font-family: Inter;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0px;
    font-variation-settings: 'opsz' 12;
    font-weight: ${props.semiBold ? 600 : 'normal'};
  `,
}

const modifiersBold = ({ bold }: ModifiersProps) => css`
  font-family: Inter;
  font-style: normal;
  font-weight: ${bold ? 'bold' : 'normal'};
`

const InterVariants: Record<
  Types.BoldVariantsProps['variant'],
  (props: ModifiersProps) => FlattenSimpleInterpolation
> = {
  'body-1': (props: ModifiersProps) => css`
    ${modifiersBold(props)}
    font-size: 18px;
    line-height: auto;
    letter-spacing: 0px;
  `,
  'body-2': (props: ModifiersProps) => css`
    ${modifiersBold(props)}
    font-size: 16px;
    line-height: auto;
    letter-spacing: 0px;
  `,
  'body-3': (props: ModifiersProps) => css`
    ${modifiersBold(props)}
    font-size: 14px;
    line-height: auto;
    letter-spacing: 0px;
  `,
  'caption-1': (props: ModifiersProps) => css`
    ${modifiersBold(props)}
    font-size: 12px;
    line-height: auto;
    letter-spacing: 0px;
  `,
  'caption-2': (props: ModifiersProps) => css`
    ${modifiersBold(props)}
    font-size: 10px;
    line-height: auto;
    letter-spacing: 0px;
  `,
}

export const modifiers = {
  ...CitrinaVariants,
  ...InterVariants,
}

export const commonStyles = (props: Types.TextProps) => css`
  color: ${`var(--${props.color}${props.colorWeight})`};
  ${modifiers[props.variant](props)}
`
